import Vue from 'vue'

import { getters, actions } from '@/store/data'
import { getters as cGetters} from '@/store/config'



function singleSensorHandler(device: string, datapoint: Record<string, any>): void {
  const angle = datapoint.bend_axis;
  actions.newData(device, angle)
  actions.newComputedData(Math.abs(angle))
}

function multipleSensorsHandler(device: string, datapoint: Record<string, any>): void {
  // Calculate sensor tilt
  const angle = datapoint.bend_axis
  actions.newData(device, angle)

  const positions = cGetters.positions()
  const positionArray = Object.keys(positions);

  const up = positionArray[0];
  const down = positionArray[1];

  const angleUp = getters.deviceData(positions[up])
  const angleDown = getters.deviceData(positions[down])

  if (angleUp && angleDown) {
    actions.newComputedData(angleUp - angleDown)
    getters.reset(positions[up])
    getters.reset(positions[down])
  }
}

export function getDataHandlerForSensorCount(): string {
  const count = cGetters.sensorCount();

  if (count === 1) return 'one-sensor'
  else return 'multiple-sensor'
}

const datahandlers: { [K: string]: (device: string, datapoint: any) => void } = {
  'one-sensor': singleSensorHandler,
  'multiple-sensor': multipleSensorsHandler
}

export default datahandlers