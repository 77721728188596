import _Vue from 'vue'

const Strings = {
  rugbuiging: {

    config: {
      errEqual: 'Bovenste en onderste sensor kunnen niet dezelfde zijn.',
      errEmpty: 'Vul alle selectboxes in.',
    },
  configModal: {
    step1: {
      explanation: [
        'Deze configuratie bepaalt de startwaardes voor de meting. De onderlinge hoek tussen de sensoren wordt bepaald voor de minimale buiging (rechtopstaand) en maximale buiging (staand, voorovergebogen).',
        'Klik op Instellen als minimum/maximum als de huidige meetwaarde bij rechtop staan/vooroverbuigen gestabiliseerd is.',
        'Configuratie voltooid. Klik op "Afsluiten" om dit menu af te sluiten.'
        ]  
      }
    },
  },
  kniebuiging: {

  },
  schouderflexie: {

  }
}

export function staticTextPlugin(Vue: typeof _Vue,options?:any) {
  Vue.prototype.$staticText = Strings
}


