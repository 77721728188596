














































































































import Vue from 'vue'
import _ from 'lodash'
import RugbuigingLogo from '@/assets/images/icoon-rugbuiging.svg'
import KniebuigingLogo from '@/assets/images/icoon-kniebuiging.svg'
import SchouderflexieLogo from '@/assets/images/icoon-schouderflexie.svg'
import BovenRugLogo from '@/assets/images/icoon-bovenrug.svg'
import NekLogo from '@/assets/images/icoon-nek.svg'

export default Vue.extend({
  name: 'NavBar',
  created() {
    window.addEventListener('resize', this.resizeHandle)
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandle)
  },
  components: {
    RugbuigingLogo,
    KniebuigingLogo,
    SchouderflexieLogo,
    NekLogo,
    BovenRugLogo
  },
  props: {
    navAreaId: {
      type: String,
      default: 'nav-area'
    },
  },
  data() {
    return {
      showMenu: window.innerWidth > 992,
      smallScreen: window.innerWidth < 992,
      showVersionInfo: false,
      debugCounter: 0,
      timer: 0,
    }
  },
  methods: {
    version() {
      return process.env.VUE_APP_GIT_HASH;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
      this.$emit('toggle', this.showMenu)
    },
    toHome() {
      this.$router.push({
        name: 'Home'
      });

      this.debugCounter++;

      window.clearInterval(this.timer)
      if (this.debugCounter > 9) {
        this.showVersionInfo = true
        this.resetAfterTimeout()

        console.log('App version', process.env.VUE_APP_GIT_HASH);

      }
      else
      {
        this.timer = window.setInterval(this.resetDebugCounter,1000);
      }
    },
    resetDebugCounter() {
      this.debugCounter = 0;
      this.showVersionInfo = false;
    },
    resetAfterTimeout() {
      window.setInterval(this.resetDebugCounter,15000)
    },
    clickedNavArea(event: Event) {
      const clickEvent = event.target as HTMLDivElement
      if((clickEvent.id == this.navAreaId)) {
        this.closeNav()
      }
    },
    clickOutsideNavArea() {
      this.closeNav()
    },
    closeNav() {
      this.smallScreen = window.innerWidth < 1230
      if (this.smallScreen){
        this.showMenu = false
        this.$emit('toggle', this.showMenu)
      }
    },

    // eslint-disable-next-line
    resizeHandle: _.debounce(function(this: any) {
      this.showMenu = window.innerWidth > 1230
    }, 100)
  },
})
