import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import { getters } from '@/store/config'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/application/:applicationName/',
    component: () => import('@/views/FunctionPage.vue'),
    props: true
    // children: [
    //   {
    //     path: 'config',
    //     name: 'Config',
    //     component: () => import('@/views/Config.vue')
    //   },
    //   {
    //     path: 'result',
    //     name: 'Result',
    //     component: () => import('@/views/Result.vue')
    //   },
    // ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  }, 
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = getters.authenticated();
  
  if ((!to.name || to.name !== 'Login') && !isAuthenticated) {
    next('login')
  }
  
  next();
})

    
//   // check the vender
//   // navigation should not be shown when signing in

export default router
