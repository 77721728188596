import sleep from '@/helpers/sleep';
import { actions } from '@/store/data';
import { SensorBase, SensorData, SENSOR_TYPE } from "./SensorBase";
import { DATA_COMMAND_ST, DATA_COMMAND_T, DATA_RESPONSE_ST, DATA_RESPONSE_T, SERVICE_BATT } from "../constants";

export class Movesense extends SensorBase {
    static _serviceUUID = "56534944-2020-4e49-4b45-2041544c4153"
    static _controlUuid = "56530001-2020-4e49-4b45-2041544c4153";
    static _measureUuid = "56530002-2020-4e49-4b45-2041544c4153";

    get type(): SENSOR_TYPE {
        return SENSOR_TYPE.SENSOR_TYPE_MOVESENSE
    }

    constructor(device: BluetoothDevice) {
        super(device);
        
        this._deviceName = device.name;

        console.log(device.name + " sensor initiated");

        this.handleBattLevel = this.handleBattLevel.bind(this);
        this.unsubscribeBatteryChar = this.unsubscribeBatteryChar.bind(this);
        this.handleMeasureNotification = this.handleMeasureNotification.bind(this);
    }
    _convertAngle(angleY: number, angleZ: number): number {
        let result = angleY
        // Arctangent is 'good' around 0 degrees, 'bad' around 90 degrees
        // When Z=> +/- 90, Y => +/-0.
        // So when Z reaches extreme, use Y. And vice versa
        if (angleZ >= 30) {
          result = 180 - angleY
        } else if (angleZ <= -30) {
          result = angleY % 360
        } else if (angleY >= 30) {
          result = 90 + angleZ
        } else if (angleY <= -30) {
          result = 270 - angleZ
        }
      
        return result
    }

    customDataParser(event: DataView): SensorData {
        const cmd_st = event.getUint8(1)
        const data = this.initSensorData();

        switch (cmd_st) {
            case DATA_RESPONSE_ST.ANGLE_DATA:
                data.t = event.getUint32(3, true);
                data.x = event.getFloat32(7, true);
                data.y = event.getFloat32(11, true) //* -1 + 90;
                data.z = event.getFloat32(15, true);
                data.bend_axis = this._convertAngle(data.y, data.z);
                break;
            default:
                console.log('UNKNOWN subcommand: ' + cmd_st)
                break;
        }

        return data;
    }

    unsubscribeAll(): void {
        const unsubscribeCommand = new Uint8Array([
            DATA_COMMAND_T.UNSUBSCRIBE,
            DATA_RESPONSE_ST.ANGLE_DATA
        ])

        this._control?.writeValue(unsubscribeCommand);
        
        this.unsubscribeBatteryChar();
        this._measure?.stopNotifications();
        this._measure?.removeEventListener("characteristicvaluechanged", this.handleMeasureNotification);
    }

    async discoverServices(): Promise<void> {
        const service = await this._device?.gatt?.getPrimaryService(Movesense._serviceUUID);
        const allCharacteristics = await service?.getCharacteristics();

        if (!allCharacteristics) return;

        this._control = allCharacteristics.find((element) => element.uuid === Movesense._controlUuid);
        this._measure = allCharacteristics.find((element) => element.uuid === Movesense._measureUuid);

        this._measure?.addEventListener("characteristicvaluechanged", this.handleMeasureNotification);

        await this.subscribeBatteryChar();
        await this.start();
    }

    handleMeasureNotification(event: Event): void {
        const target = event.target as BluetoothRemoteGATTCharacteristic;
        const response = target.value;
        const deviceName = this._device?.name;

        if (response) {
            const cmd_t = response.getUint8(0)
            
            switch (cmd_t) {
                case DATA_RESPONSE_T.COMMAND_RESULT: {
                    this.handleCommandResponse(response);
                    break;
                }
                case DATA_RESPONSE_T.DATA:
                    if (response && deviceName) {
                        const data = this.customDataParser(response);
                        actions.newSensorData(deviceName, data);
                    }
                    break;
                default:
                    console.log('UNKNOWN RESPONSE: ' + cmd_t);
                break;
            }
        }
    }

    async subscribeBatteryChar() {
        const service =  await this._device?.gatt?.getPrimaryService(SERVICE_BATT);
        const allCharacteristics = await service?.getCharacteristics();

        if (!allCharacteristics) return;

        this._battery = allCharacteristics[0];
        this._battery?.startNotifications().then(() => {
            this._battery?.addEventListener('characteristicvaluechanged', this.handleBattLevel);
        })
    }

    public unsubscribeBatteryChar(): void {
        this._battery?.stopNotifications().then(() => {
            this._battery?.removeEventListener('characteristicvaluechanged', this.handleBattLevel);
        })
    }

    private handleBattLevel(event: Event) {
        const target = event.target as BluetoothRemoteGATTCharacteristic;
        const response = target.value;
        
        if (response && this._device?.name) {
            const val = response.getUint8(0);
            actions.newBattLevel(this._device.name, val);
        }
    }

    private handleCommandResponse(response: DataView) {
        const cmd_st = response.getUint8(1);

        if (cmd_st === DATA_RESPONSE_ST.SUBSCRIBE_FAIL) {
            console.log('SUBSCRIBE failed')
        } else if (cmd_st === DATA_RESPONSE_ST.SUBSCRIBE_SUCCES) {
            console.log('SUBSCRIBE successful')
        } else if (cmd_st === DATA_RESPONSE_ST.UNSUBSCRIBE_FAIL) {
            console.log('UNSUBSCRIBE failed')
        } else if (cmd_st === DATA_RESPONSE_ST.UNSUBSCRIBE_SUCCES) {
            console.log('UNSUBSCRIBE successful')
        }
    }

    async startStream(): Promise<void> {
        console.log("Movesense: start stream");

        if (this._measure != null && this._control != null) {
            await sleep(250);
            await this._measure?.startNotifications();
            await sleep(250);
            await this._control.writeValue(new Uint8Array([DATA_COMMAND_T.SUBSCRIBE, DATA_COMMAND_ST.ANGLE_DATA]))
        }
    }
}