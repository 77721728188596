export const NAME = 'Atlas'

export const SERVICE_BATT = 'battery_service'
export const SERVICE_CONN = '56534944-4845-524d-4553-2041544c4153'
export const SERVICE_DATA = '56534944-2020-4e49-4b45-2041544c4153'

export const CHAR_BATT = 'battery_level'

export const CHAR_CONN_WRITE = '56530001-4845-524d-4553-2041544c4153'
export const CHAR_CONN_READ = '56530002-4845-524d-4553-2041544c4153'

export const CHAR_DATA_WRITE = '56530001-4845-524d-4553-2041544c4153'
export const CHAR_DATA_READ = '56530002-4845-524d-4553-2041544c4153'

export const DATA_COMMAND_T = {
  SUBSCRIBE: 1,
  UNSUBSCRIBE: 2,
  SETTINGS: 3
};

export const DATA_COMMAND_ST = {
  ANGLE_DATA: 1,
  RAW_DATA: 2,
  FREQUENCY: 3,
};

export const DATA_RESPONSE_T = {
  COMMAND_RESULT: 1,
  DATA: 2
};

export const DATA_RESPONSE_ST = {
  ANGLE_DATA: 1,
  RAW_DATA: 2,
  SUBSCRIBE_SUCCES: 3,
  SUBSCRIBE_FAIL: 4,
  UNSUBSCRIBE_SUCCES: 5,
  UNSUBSCRIBE_FAIL: 6,
  SETTINGS_CHANGE_SUCCES: 7,
  SETTINGS_CHANGE_FAIL: 8,
  SUBSCRIPTION_IN_PROGRESS: 9
};