import Vue from 'vue'
import BLEManager from '@/managers/bluetooth.manager'
import { SensorBase } from '@/managers/sensors/SensorBase'

const state = Vue.observable({
  devices: [] as SensorBase[],
  commandChars: {} as Record<string, BluetoothRemoteGATTCharacteristic>,
  dataChars: {} as Record<string,BluetoothRemoteGATTCharacteristic>
})

export const getters = {
  devices(): SensorBase[] {
    return state.devices;
  },
  deviceCount(): number {
    return state.devices.length
  },
  dataChars(): Record<string,BluetoothRemoteGATTCharacteristic> {
    return state.dataChars
  },
  getCommandCharByName(deviceName: string): BluetoothRemoteGATTCharacteristic {
    return state.commandChars[deviceName]
  },
  getDataCharByName(deviceName: string): BluetoothRemoteGATTCharacteristic {
    return state.dataChars[deviceName]
  }
}

export const actions = {
  scanForDevices(): void {
    BLEManager.getInstance().scan();
  },
  
  disconnect(device: BluetoothDevice): void {
    BLEManager.getInstance().disconnect(device);
    mutations.removeDevice(device)
  }
}

export const mutations = {
  addDevice(device: SensorBase): void {
    state.devices.push(device)
  },
  addDataChar(deviceName: string, chars: BluetoothRemoteGATTCharacteristic[]): void {
    chars.forEach(c => {
      const uuid16 = c.uuid.slice(4,8)
      if (uuid16 == '0001') {
        state.commandChars[deviceName] = c
      } else if (uuid16 == '0002') {
        state.dataChars[deviceName] = c
      }
    })
  },
  removeDevice(device: BluetoothDevice): void {
    state.devices = state.devices.filter(d => d._device?.id != device.id)
  }
}