


















import NavBar from '@/components/layout/NavBar.vue'
import { getters } from '@/store/config'

import Vue from 'vue'
export default Vue.extend({
  components: {
    NavBar,
  },
  data() {
    return {
      menuOpen: false
    }
  },
  props: {
  },
  methods: {
    menuToggled(value: boolean) {
      this.menuOpen = value;
    }
  },
  computed: {
    authenticated() {
      return getters.authenticated();
    },
  }
})
