/// <reference types="web-bluetooth" />

import Dot from './sensors/Dot';
import {mutations} from '../store/devices';
import { Movesense } from './sensors/Movesense';
import { SensorBase, SENSOR_TYPE } from './sensors/SensorBase'
import sleep from '@/helpers/sleep';

const options = {
  filters: [
    { namePrefix: 'Step' },
    { namePrefix: 'Xsens DOT'},
    { services: [SensorBase._service_battery] },
  ],
  optionalServices: [Dot._serviceUUID, Movesense._serviceUUID, Dot._batteryUuid, Dot._configurationService]
}

class BLEManager {

  private static instance: BLEManager;

  private _connectedDevices: SensorBase[] = [];

  public get hasConnectedDevices(): boolean {
    return this._connectedDevices.length > 0;
  }

  public get connectedDevices(): SensorBase[] {
    return this._connectedDevices;
  }

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() { console.log('new ble manager instantiated') }

  public static getInstance(): BLEManager {
    if (!BLEManager.instance) {
      BLEManager.instance = new BLEManager();
    }

    return BLEManager.instance;
  }

  public scan(): void {
    navigator.bluetooth.requestDevice(options).then(async (device) => {
      if (device.name) {
        const type = SensorBase.typeFromString(device.name);

        let connectedDevice;

        switch (type) {
          case SENSOR_TYPE.SENSOR_TYPE_MOVESENSE:
            connectedDevice = new Movesense(device);
            break;
          case SENSOR_TYPE.SENSOR_TYPE_DOT:
            connectedDevice = new Dot(device);
            break;
          case SENSOR_TYPE.SENSOR_TYPE_NONE:
            console.log('somehow connnected to a unsupported device... well done')
            break;
          default:
            console.log('default case should not happen')
        }

        if (connectedDevice) {
          mutations.addDevice(connectedDevice);
          this._connectedDevices.push(connectedDevice);
        }

        device.addEventListener('gattserverdisconnected', this.onDisconnected.bind(this));
        return this.connect(device);
      }
    }).then(connectedDevice => {
      return connectedDevice
    })
  }

  public async connect(device: BluetoothDevice): Promise<BluetoothDevice | void> {
    return device.gatt?.connect().then(async () => {
        const sensor = this._connectedDevices.find((element) => {
          return element._device?.id === device.id
        })

        if (sensor) sensor.init();
    }).catch((reason) => {
      console.error('failed to connnect: ' + reason);
      
      this._removeSensorFromConnectedDevices(device);
    });
  }

  public unsubscribeAllDevices(): void {
    this._connectedDevices.map((el) => {
      el.unsubscribeAll();
    })

    this._connectedDevices = [];
  }

  public disconnect(device: BluetoothDevice): void {
    if (!device) return;
    
    device.gatt?.disconnect();
    this._removeSensorFromConnectedDevices(device);
  }

  onDisconnected(event: Event): void {
    const target = event.target as BluetoothDevice;
    this.disconnect(target);
  }

  private _removeSensorFromConnectedDevices(b: BluetoothDevice) {
    this._connectedDevices = this._connectedDevices.filter((sensor) => sensor._device?.id !== b.id)
  }
}

export default BLEManager
