import sleep from '@/helpers/sleep';
import { actions } from '@/store/data';
import { SensorBase, SensorData, SENSOR_TYPE } from "./SensorBase";

export default class Dot extends SensorBase {
    static _serviceUUID = "15172000-4947-11e9-8646-d663bd873d93"
    static _controlUuid = "15172001-4947-11e9-8646-d663bd873d93";
    static _measureUuid = "15172004-4947-11e9-8646-d663bd873d93";
    static _batteryUuid = "15173000-4947-11e9-8646-d663bd873d93";

    static _configurationService = "15171000-4947-11e9-8646-d663bd873d93";
    static _configurationUuid = "15171002-4947-11e9-8646-d663bd873d93";

    _configuration: BluetoothRemoteGATTCharacteristic | undefined;

    _last = +new Date();

    get type(): SENSOR_TYPE {
        return SENSOR_TYPE.SENSOR_TYPE_DOT
    }

    constructor(device: BluetoothDevice) {
        super(device);

        this._deviceName = this._device?.name + ' ' + device.id.substring(0, 3);

        console.log(this._deviceName + " instantiated");

        this.handleBattLevel = this.handleBattLevel.bind(this);
        this.unsubscribeBatteryChar = this.unsubscribeBatteryChar.bind(this);
        this.handleMeasureNotification = this.handleMeasureNotification.bind(this);
    }

    customDataParser(event: DataView): SensorData {
        const data = this.initSensorData();

        try {
            // Convert the original axis to the movesense axis definitions to have on uniform way of axis orientation.
            const x_org = event.getFloat32(4, true);
            const y_org = event.getFloat32(8, true);
            const z_org = event.getFloat32(12, true);

            data.t = event.getFloat32(0, true);
            // data.x = -y_org;
            // data.y = -x_org;
            // data.z = -z_org;

            data.x = x_org;
            data.y = y_org;
            data.z = z_org;

            data.bend_axis = y_org

            console.log("x" + data.x + " y: " + data.y + " z: " + data.z)


            // data.bend_axis = data.x
        } catch (e) {
            console.error("catch: " + e)
        }

        return data;
    }

    unsubscribeAll(): void {
        const unsubscribeCommand = new Uint8Array([
            0x01,
            0x00,
            0x04
        ])

        this._control?.writeValue(unsubscribeCommand);

        this.unsubscribeBatteryChar();
        this._measure?.stopNotifications();
        this._measure?.removeEventListener("characteristicvaluechanged", this.handleMeasureNotification);
    }

    public unsubscribeBatteryChar(): void {
        this._battery?.stopNotifications().then(() => {
            this._battery?.removeEventListener('characteristicvaluechanged', this.handleBattLevel);
        })
    }

    private handleBattLevel(event: Event) {
        const target = event.target as BluetoothRemoteGATTCharacteristic;
        const response = target.value;

        if (response && this._deviceName) {
            // TODO - Account for uniqye names

            const val = response.getUint8(0);
            actions.newBattLevel(this._deviceName, val);
        }
    }

    async discoverServices(): Promise<void> {
        const service = await this._device?.gatt?.getPrimaryService(Dot._serviceUUID);
        const allCharacteristics = await service?.getCharacteristics();

        if (!allCharacteristics) return;

        this._control = allCharacteristics.find((element) => element.uuid === Dot._controlUuid);
        this._measure = allCharacteristics.find((element) => element.uuid === Dot._measureUuid);

        this._measure?.addEventListener("characteristicvaluechanged", this.handleMeasureNotification);

        const batteryService = await this._device?.gatt?.getPrimaryService(Dot._batteryUuid);
        const batteryCharacteristic = await batteryService?.getCharacteristics();

        if (!batteryCharacteristic) return;

        this._battery = batteryCharacteristic[0];
        this._battery?.addEventListener('characteristicvaluechanged', this.handleBattLevel);

        const configurationService = await this._device?.gatt?.getPrimaryService(Dot._configurationService);
        const configurationCharacteristics = await configurationService?.getCharacteristics();

        this._configuration = configurationCharacteristics?.find((element) => element.uuid === Dot._configurationUuid);

        await this.start();
    }

    handleMeasureNotification(event: Event): void {
        const target = event.target as BluetoothRemoteGATTCharacteristic;
        const response = target.value;

        const now = +new Date();
        if (response && this._deviceName) {
            this._last = now;

            const data = this.customDataParser(response);
            actions.newSensorData(this._deviceName, data);
        }
    }

    async startStream(): Promise<void> {
        console.log("Xsens DOT: starting stream")

        if (this._measure != null && this._control != null && this._battery != null && this._configuration != null) {
            this.readConfigurationValue();

            await this._measure.startNotifications();
            this.notifyStream = this._measure.value;
            await this._control.writeValue(new Uint8Array([0x01, 0x01, 0x04]))
            await this._battery?.startNotifications();


            const response = await this._battery.readValue();
            const battery = response.getUint8(0);

            if (this._device?.name) {
                actions.newBattLevel(this._device.name, battery);
            }
        }
    }

    async readConfigurationValue(): Promise<void> {
        if (this._configuration != null) {
            // Sleep 500 ms since bluetooth otherwise disconnects
            await sleep(500);
            // Get the configuration
            const value = await this._configuration.readValue();
            // Overwrite the HZ value
            value.setInt8(24, 15);
            // Write back the configuration
            this._configuration.writeValue(value);
        }
    }
}