import Vue from 'vue'

import { getters as cGetters } from '@/store/config'
import datahandlers, {getDataHandlerForSensorCount} from '@/helpers/datahandlers'

const maxSensorDataLength = 200
interface SensorData {
  data: number[];
  idx: number;
  maxLen: number;
}

const maxComputedDataLength = 100
interface ComputedData {
  data: number[];
  idx: number;
  maxLen: number;
}

// Data and computed data structure is bad.
// Implement buffer-pipeline somewhere
const state = Vue.observable({
  deviceData: {} as Record<string, SensorData>,
  computedAngles: {} as ComputedData,
  latest: 0 as number,
  log: [] as Record<string,unknown>[],
  battLevels: {} as Record<string, number>,
  lastTime: new Date().getTime() as number
})

export const getters = {
  deviceData(deviceName: string): number {
    if(!state.deviceData[deviceName]) {
      console.error("Data not received for this device! - " + deviceName)
      return NaN;
    }

    const idx = state.deviceData[deviceName].idx
    if (idx == -1) {
      return NaN;
    }

    const data = state.deviceData[deviceName].data[idx]
    return Number(data)
  },
  reset(deviceName: string): void {
    state.deviceData[deviceName].idx = -1
  },
  computedAngles(): number[] {
    return state.computedAngles.data
  },
  computedAnglesLength(): number {
    return state.computedAngles.idx
  },
  latest(): number {
    return state.latest
  },
  log(): Record<string,unknown>[] {
    return state.log
  },
  batteryLevels(): Record<string, number> {
    return state.battLevels
  },
}

export const actions = {
  newSensorData(deviceName: string, datapoint: Record<string,number>): void {
    if (Object.values(cGetters.positions()).includes(deviceName)) {
      const handler: string = getDataHandlerForSensorCount();
      datahandlers[handler](deviceName, datapoint)
    }
  },
  newData(deviceName: string, datapoint: number): void {
    mutations.addData(deviceName, datapoint)
  },
  newComputedData(datapoint: number):void {
    mutations.addCompData(datapoint)
  },
  clearData(): void {
    mutations.clearAllData()
  },
  newLog(data: Record<string,unknown>): void {
    mutations.addLog(data)
  },
  newBattLevel(deviceName: string, level: number): void{
    mutations.addNewBatteryLevel(deviceName, level)
  }
}

export const mutations = {
  addData(deviceName: string, sample: number): void {
    // Not very memory friendly
    if(!state.deviceData[deviceName]) {
      state.deviceData[deviceName] = {idx: -1, maxLen: maxSensorDataLength, data: new Array(maxSensorDataLength)}
    } 
    
    const idx = state.deviceData[deviceName].idx
    state.deviceData[deviceName].data[idx + 1] = sample
    state.deviceData[deviceName].idx = (idx + 1) % state.deviceData[deviceName].maxLen
  },

  addCompData(datapoint: number): void {
    if (!state.computedAngles.data) {
      state.computedAngles.data = new Array(maxComputedDataLength)
    }
    state.computedAngles.data[state.computedAngles.idx] = datapoint
    state.computedAngles.idx = state.computedAngles.idx + 1

    if (state.computedAngles.idx > maxComputedDataLength)
    {
      state.computedAngles.idx = 0
    }

    state.latest = datapoint;
  },

  clearAllData(): void {
    state.deviceData = {}
    state.computedAngles.idx = 0
    state.latest = 0;
  },
  addLog(data: Record<string,unknown>): void {
    state.log.push(data)
  },
  clearLog(): void {
    state.log.length = 0
  },
  addNewBatteryLevel(deviceName: string, level: number): void{
    Vue.set(state.battLevels, deviceName, level)
  }
}