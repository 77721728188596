import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/css/constants.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faExclamationCircle, faPlus, faMinus, faChevronRight, faBars, faCog, faPlay, faPause, faVolumeUp, faVolumeMute, faTrashAlt, faBullseye, faArrowLeftRotate, faCircle  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { staticTextPlugin } from './plugins/staticTextPlugin'

library.add(faTimes, faExclamationCircle, faPlus, faMinus, faChevronRight, faBars, faCog, faPlay, faPause, faVolumeUp, faVolumeMute, faTrashAlt, faBullseye, faArrowLeftRotate, faCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(staticTextPlugin)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
