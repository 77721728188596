export enum SENSOR_TYPE {
    SENSOR_TYPE_DOT,
    SENSOR_TYPE_NONE,
    SENSOR_TYPE_MOVESENSE
}

export type SensorData = {
    t: number,
    x: number,
    y: number,
    z: number,
    bend_axis: number,
}

export abstract class SensorBase {
    static _service_battery = "battery_service";

    _deviceName: string | undefined;
    _device: BluetoothDevice | undefined;
    _services: BluetoothRemoteGATTService[] = [];
    _characteristics: BluetoothRemoteGATTCharacteristic[] = [];

    _measure: BluetoothRemoteGATTCharacteristic | undefined;
    _control: BluetoothRemoteGATTCharacteristic | undefined;
    _battery: BluetoothRemoteGATTCharacteristic | undefined;

    notifyStream: DataView | undefined;

    constructor(device: BluetoothDevice) {
        this._device = device;
    }

    async init(): Promise<void> {
        await this.discoverServices();
    }

    initSensorData(): SensorData  {
        const data: SensorData = {
            t: 0,
            x: 0,
            y: 0,
            z: 0,
            bend_axis: 0
        }

        return data;
    }

    async start(): Promise<void> {
        await this.startStream();

        if (!this.notifyStream) return;
        this._measure?.addEventListener('characteristicvaluechanged', (ev) => this._parseStream(ev));
    }

    static typeFromString(type: string): SENSOR_TYPE {
        if(type.includes("Step")) return SENSOR_TYPE.SENSOR_TYPE_MOVESENSE
        if(type.includes("DOT")) return SENSOR_TYPE.SENSOR_TYPE_DOT
        return SENSOR_TYPE.SENSOR_TYPE_NONE;
    }

    private _doVisual() {
        console.log("Visual: not implemented");
    }

    private _doShock() {
        console.log("Shock: not implemented");
    }

    private _doHaptic() {
        console.log("Haptic: not implemented");
    }

    abstract get type(): SENSOR_TYPE;
    
    abstract startStream(): void
    abstract unsubscribeAll(): void;
    abstract discoverServices(): Promise<void>;
    private _parseStream(event: Event): void {
        console.log('target', event.target);
        // add to custom data parser and stuff, but what? target or value?
    }
}