import Vue from 'vue'

import { getters as deviceGetters, actions as deviceActions } from '@/store/devices'
import { actions as dActions } from '@/store/data'
import { ChartMode } from '@/types/chart_modes'

export enum ConfigState {
  NO_CONFIG,
  INCOMPLETE,
  COMPLETED
}

const state = Vue.observable({
  application: Vue.observable({}) as Record<string,any>,
  state: ConfigState.NO_CONFIG as ConfigState,
  positions: {} as Record<string,string>,
  authenticated: false as boolean,
  showConnect: false as boolean,
  chartMode: null as ChartMode,
  graphMaxAngle: 180 as number,
  maxDatapoints: 66 as number,
  graphMinAngle: 0 as number,
  sensorCount: -1 as number,
  minAngle: -90 as number,
  maxAngle: -90 as number,
  limit: 70 as number
})

export const getters = {
  application(): Record<string,any> {
    return state.application
  },
  authenticated(): boolean {
    return state.authenticated
  },
  chartMode(): ChartMode {
    return state.chartMode
  },
  positions(): Record<string,string> {
    return state.positions
  },
  minAngle(): number{
    return state.minAngle
  },
  maxAngle(): number {
    return state.maxAngle
  },
  graphMinAngle(): number {
    return state.graphMinAngle
  },
  graphMaxAngle(): number {
    return state.graphMaxAngle
  },
  limit(): number {
    return state.limit
  },
  sensorCount(): number {
    return state.sensorCount
  },
  showConnect(): boolean {
    return state.showConnect
  },
  configComplete(): boolean {
    return state.state == ConfigState.COMPLETED
  },
  maxDatapoints(): number {
    return state.maxDatapoints;
  }
}

export const actions = {
  setApplication(application: Record<string, any>): void {
    mutations.clearConfig()
    dActions.clearData()
    mutations.updateApplication(application)

    deviceGetters.devices().map((el) => {
      if (el._device) {
        deviceActions.disconnect(el._device);
      }
    });

    actions.setLimit(70);
  },
  setMinGraphAngle(angle: number): void {
    mutations.updateMinGraphAngle(angle);
  },
  setMaxGraphAngle(angle: number): void {
    mutations.updateMaxGraphAngle(angle);
  },
  setAuthenticated(auth: boolean): void {
    mutations.updateAuthenticated(auth);
  },
  setConfigState(): void {
    mutations.updateConfigState();
  },
  setChartMode(mode: ChartMode): void {
    mutations.updateChartMode(mode);
  },
  setSensorCount(count: number): void {
    mutations.updateSensorCount(count)
  },
  setShowConnect(toggle: boolean): void {
    mutations.updateShowConnect(toggle)
  },
  setPosition(deviceName: string, position: string): void {
    mutations.updatePosition(deviceName, position)
    mutations.updateConfigState()
  },
  setMinAngle(angle: number): void {
    mutations.updateMinAngle(angle)
    mutations.updateConfigState()
  },
  setMaxAngle(angle: number): void {
    mutations.updateMaxAngle(angle)
    mutations.updateConfigState()
  },
  setLimit(limit: number): void {
    mutations.updateLimit(limit)
  },
  setMaxDatapoints(max: number): void {
    mutations.updatemaxDatapoints(max);
  }
}

export const mutations = {
  updatemaxDatapoints(max: number): void {
    state.maxDatapoints = max;
  },
  updateMinGraphAngle(angle: number): void {
    state.graphMinAngle = angle;
  },
  updateMaxGraphAngle(angle: number): void {
    state.graphMaxAngle = angle;
  },
  updateApplication(application: Record<string,any>): void {
    state.application=application
  },
  updateAuthenticated(auth: boolean): void {
    state.authenticated = auth
  },
  updatePosition(deviceName: string, position: string): void {
    state.positions[position] = deviceName
  },
  updateChartMode(mode: ChartMode): void {
    state.chartMode = mode
  },
  updateSensorCount(count: number): void {
    state.sensorCount = count
  },
  updateShowConnect(toggle: boolean): void {
    state.showConnect = toggle
  },
  updateMinAngle(angle: number): void {
    state.minAngle = angle
  },
  updateMaxAngle(angle: number): void {
    state.maxAngle = angle
  },
  updateLimit(limit: number): void {
    state.limit = limit
  },
  updateConfigState(): void {
    if (state.sensorCount == 2) {
      if (
        state.minAngle == -90 ||
        state.maxAngle == -90) {
        state.state = ConfigState.INCOMPLETE
      } else {
        state.state = ConfigState.COMPLETED
      }
    } else {
      const position: string = Object.keys(state.positions)[0];

      if (!position || !state.chartMode) {
        state.state = ConfigState.INCOMPLETE
      } else {
        state.state = ConfigState.COMPLETED
      }
    }
  },
  clearConfig(): void {
    state.state = ConfigState.NO_CONFIG
    state.showConnect = false
    state.chartMode = null
    state.maxDatapoints = 66
    state.sensorCount = -1
    state.positions = {}
    state.minAngle = -90
    state.maxAngle = -90
    state.limit = 70
    }
}